export default {
  global: {
    body: {
      overflowX: 'hidden',
      bg: '#fafafa',
      minWidth: '320px',
    },
    label: {
      alignItems: 'center !important',
    },
  },
};
