export const Textarea = {
  baseStyle: {
    fontWeight: 'bold',
    _placeholder: {
      color: 'rgba(17, 17, 17, 0.32)',
      fontWeight: 'bold',
      fontsize: '18px',
    },
  },
};
