export const text = {
  _hover: {
    color: '#FD7E50',
    opacity: '1',
  },
  textAlign: 'left',
  width: '100%',
  mb: '10px',
  opacity: '.4',
};
